<template>
  <button
    :class="{
      'd-button': true,
      'd-button--primary': primary,
      'd-button--disabled': disabled,
      'd-button--block': block,
    }"
    :style="{
      '--d-button-bg-color': color ? color : 'var(--v-primary-base)',
    }"
    type="button"
    :disabled="disabled"
  >
    <div v-if="loading" class="d-button__loader">
      <v-progress-circular indeterminate width="2" size="20" />
    </div>
    <slot v-else />
  </button>
</template>

<script>
export default {
  name: 'DButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
$greyLight: #f1f1f1;
$grey: #aaaaaa;

.d-button {
  padding: rem(16) rem(16);
  font-size: rem(16);
  font-weight: 500;
  border-radius: 5px;
  text-align: center;
  min-width: rem(120);
  color: var(--d-button-bg-color);

  &--primary {
    background-color: var(--d-button-bg-color);
    color: #ffffff;
    min-width: rem(180);
  }

  &--disabled {
    color: $grey;
  }
}

.d-button--primary.d-button--disabled {
  background-color: $greyLight;
  color: $grey;
}

@media only screen and (max-width: 768px) {
  .d-button {
    &--block {
      width: 100%;
    }
  }
}
</style>
