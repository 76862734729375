var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:{
    'd-button': true,
    'd-button--primary': _vm.primary,
    'd-button--disabled': _vm.disabled,
    'd-button--block': _vm.block,
  },style:({
    '--d-button-bg-color': _vm.color ? _vm.color : 'var(--v-primary-base)',
  }),attrs:{"type":"button","disabled":_vm.disabled}},[(_vm.loading)?_c('div',{staticClass:"d-button__loader"},[_c('v-progress-circular',{attrs:{"indeterminate":"","width":"2","size":"20"}})],1):_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }