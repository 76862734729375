import { getHeader } from '@/config/config';

export default {
  name: 'DownloadFile',
  methods: {
    async download({ url, name, type, open, openInNewTab, download } = {}) {
      const { data } =
        (await this.$http({
          method: 'get',
          url: url,
          responseType: 'arraybuffer',
          headers: getHeader(),
        })) || {};

      if (!data) return;

      const downloadFile = typeof download !== 'undefined' && type !== 'video/quicktime' ? download : true;

      if (type === 'video/quicktime') {
        open = false;
      }

      if (open || download) {
        this.downloadBlob(data, `${name}`, type, open, openInNewTab, downloadFile);
      } else {
        return window.URL.createObjectURL(new Blob([data], { type: type }));
      }
    },

    downloadBlob(data, filename, type, open, openInNewTab, download) {
      const url = window.URL.createObjectURL(new Blob([data], { type: type }));
      const link = document.createElement('a');
      link.href = url;
      if (openInNewTab) {
        link.target = '_blank';
      }
      document.body.appendChild(link);
      if (open) {
        link.click();
      }
      if (download) {
        link.setAttribute('download', filename);
        link.click();
      }
    },
  },
};
