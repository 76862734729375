<template>
  <GenericModal v-model="modalValue" :title="$t('parapharmacy.information')">
    <div class="prescription-info__container">
      <div class="prescription-info__section">
        <div class="prescriptions__prescription">
          <div class="prescriptions__icon">
            <div class="img-svg__container">
              <ElectronicPrescriptionIcon v-if="prescription === PRESCRIPTION_TYPES.ELECTRONIC"/>
              <PdfPrescriptionIcon v-if="prescription === PRESCRIPTION_TYPES.PDF"/>
              <ParapharmacyPrescriptionIcon v-if="prescription === PRESCRIPTION_TYPES.PARAPHARMACY"/>
            </div>
          </div>
          <div>
            <div class="prescriptions__title">{{ prescription.product }}</div>
            <div class="prescriptions__subtitle">{{ prescriptionType(prescription.type) }}</div>
          </div>
        </div>
      </div>
      <div class="prescription-info__section">
        <div class="prescription-info__section-title">
          {{ $t('common.professional') }}:
        </div>
        <div class="prescriptions__professional">
          <d-avatar
            :text="prescription.professional.name"
            size="38"
            rounded
            :two-letter="true"
            class="float-left"
            :src="prescription.professional.image"
          />
          <div class="prescriptions__professional-name">
            {{ prescription.professional.name }}
          </div>
        </div>
      </div>
      <div class="prescription-info__section">
        <div class="prescription-info__section-title">
          {{ $t('common.date') }}:
        </div>
        <span v-tooltip="prescription.createdAt" class="prescriptions__datetime">{{
            prescription.createdAt | moment('DD/MM/YYYY HH:mm')
          }}</span>
      </div>
    </div>
    <template v-slot:actions>
      <DButton
        :primary="true"
        @click.native="openDoc">
        {{ $t('parapharmacy.see') }}
      </DButton>
    </template>
  </GenericModal>
</template>

<script>
import GenericModal from '@/components/atoms/GenericModal.vue';
import DAvatar from "@/components/DAvatar.vue";
import DButton from "@/components/atoms/DButton.vue";

import ParapharmacyPrescriptionIcon from "@/assets/icons/receta-parafarmacia.svg";
import ElectronicPrescriptionIcon from "@/assets/icons/receta-electronica.svg";
import PdfPrescriptionIcon from "@/assets/icons/receta-pdf.svg";

const PRESCRIPTION_TYPES = {
  ELECTRONIC: 'electronic',
  PARAPHARMACY: 'parapharmacy'
};

export default {
  name: 'PrescriptionItemInfo',
  components: {DButton, GenericModal, DAvatar, ParapharmacyPrescriptionIcon, ElectronicPrescriptionIcon, PdfPrescriptionIcon },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    prescription: {
      type: Object,
      required: true,
    },
  },
  computed: {
    modalValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    prescriptionType(type) {
      let prescription = '';
      switch (type) {
        case PRESCRIPTION_TYPES.ELECTRONIC:
          prescription = this.$t('recipe.title');
          break;
        case PRESCRIPTION_TYPES.PARAPHARMACY:
          prescription = this.$t('parapharmacy.prescription');
          break;
        default:
          prescription = this.$t('recipe.paper_recipe');
      }

      return prescription;
    },
    openDoc() {
      this.$emit('openDoc');
    },
  }
};
</script>

<style lang="scss">
$black: #212121;
$grey: #F1F1F1;
$greyDark: #9E9E9E;

.prescription-info {
  &__container {
    padding-top: rem(22);
  }

  &__section {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: rem(18);
    padding-bottom: rem(18);
    max-height: rem(60);
    gap: rem(8);
    border-bottom: solid 1px $grey;
  }

  &__section-title {
    font-size: 1rem;
    color: $greyDark;
  }
}

</style>
