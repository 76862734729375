<template>
  <v-container class="py-5">
    <v-row align="center" justify="center" class="transparent pt-5">
      <v-card elevation="0" flat max-width="1100" width="100%" class="transparent mb-5">
        <v-toolbar flat class="mb-3 transparent">
          <h1 class="headline black--text">{{ $t('menu.myPrescriptions') }}</h1>
        </v-toolbar>
        <v-card>
          <v-sheet v-if="isLoading" height="300">
            <v-layout fill-height align-center justify-center>
              <v-progress-circular width="2" :indeterminate="true" color="primary" />
            </v-layout>
          </v-sheet>
          <v-simple-table v-if="!isLoading">
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="50%">{{ $t('prescriptions.prescription') }}</th>
                  <th class="hidden-xs-only">{{ $t('common.professional') }}</th>
                  <th class="hidden-xs-only">{{ $t('common.date') }}</th>
                  <th class="text-center" width="1%">{{ $t('common.actions') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in prescriptions" :key="index">
                  <td class="prescriptions__column">
                    <div class="prescriptions__prescription">
                      <div class="prescriptions__icon">
                        <div class="icon-svg__container">
                          <component :is="prescriptionImage(item.type)" />
                        </div>
                      </div>
                      <div>
                        <div class="prescriptions__title">{{ item.product }}</div>
                        <div class="prescriptions__subtitle">{{ prescriptionType(item.type) }}</div>
                      </div>
                    </div>
                  </td>
                  <td class="hidden-xs-only">
                    <div class="prescriptions__professional">
                      <d-avatar
                        :text="item.professional.name"
                        size="38"
                        rounded
                        :two-letter="true"
                        class="float-left"
                        :src="item.professional.image"
                      />
                      <div class="prescriptions__professional-name">
                        {{ item.professional.name }}
                      </div>
                    </div>
                  </td>
                  <td class="hidden-xs-only">
                    <span v-tooltip="item.createdAt" class="prescriptions__datetime">{{
                      item.createdAt | moment('DD/MM/YYYY HH:mm')
                    }}</span>
                  </td>
                  <td>
                    <div class="prescriptions__actions">
                      <div class="img-svg__container hidden-sm-and-up" @click="openPrescriptionItemInfo(item)">
                        <PrecriptionInfoIcon
                          src="@/assets/icons/boton-info-receta.svg"
                        />
                      </div>

                      <div class="img-svg__container" @click="openUrl(item)">
                        <GoToPrescriptionIcon src="@/assets/icons/boton-ver-receta.svg" />
                      </div>
                    </div>
                  </td>
                  <PrescriptionItemInfo
                    v-model="item.prescriptionItemInfo"
                    :prescription="item"
                    @openDoc="openUrl(item)"
                  />
                </tr>
                <tr v-if="!prescriptions || !prescriptions.length">
                  <td colspan="4" class="pa-5 text-center">{{ $t('prescriptions.no_prescriptions') }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <template v-if="totalResults && !isLoading">
          <div class="my-5 text-center">
            <v-pagination
              v-if="totalPages > 1"
              v-model="currentPage"
              :length="totalPages"
              text
              :total-visible="6"
              circle
              color="primary"
              @input="changePage"
            />
          </div>
        </template>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import DAvatar from '@/components/DAvatar.vue';
import Alerts from '@/mixins/Alerts';
import DownloadFile from '@/mixins/DownloadFile';
import {
  getPrescriptionsUrl,
  getHeader,
  urlPatient,
  displayNameEnv,
  parapharmacyPatientPrescriptionsUrl,
  parapharmacyPrescriptionPdfUrl,
} from '@/config/config';
import PrescriptionItemInfo from '@/views/patients/PrescriptionItemInfo.vue';

import PrecriptionInfoIcon from "@/assets/icons/boton-info-receta.svg";
import GoToPrescriptionIcon from "@/assets/icons/boton-ver-receta.svg";

const PRESCRIPTION_TYPES = {
  ELECTRONIC: 'electronic',
  PARAPHARMACY: 'parapharmacy',
};

export default {
  title: displayNameEnv + ' - ' + vm.$t('menu.myPrescriptions'),
  components: { PrescriptionItemInfo, DAvatar, PrecriptionInfoIcon, GoToPrescriptionIcon },
  mixins: [Alerts, DownloadFile],
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      perPage: 100,
      totalPages: 1,
      totalResults: 0,
      prescriptions: [],
      prescriptionOpened: null,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    };
  },
  mounted() {
    this.loadPrescriptions();
    this.loadElectronicPrescriptions();
    this.loadParapharmacyPrescriptions();
  },
  methods: {
    prescriptionType(type) {
      let prescription = '';
      switch (type) {
        case PRESCRIPTION_TYPES.ELECTRONIC:
          prescription = this.$t('recipe.title');
          break;
        case PRESCRIPTION_TYPES.PARAPHARMACY:
          prescription = this.$t('parapharmacy.prescription');
          break;
        default:
          prescription = this.$t('recipe.paper_recipe');
      }

      return prescription;
    },
    prescriptionImage(type) {
      let image = '';
      switch (type) {
        case PRESCRIPTION_TYPES.ELECTRONIC:
          image = require('@/assets/icons/receta-electronica.svg');
          break;
        case PRESCRIPTION_TYPES.PARAPHARMACY:
          image = require('@/assets/icons/receta-parafarmacia.svg');
          break;
        default:
          image = require('@/assets/icons/receta-pdf.svg');
      }

      return image;
    },
    openPrescriptionItemInfo(prescription) {
      this.prescriptions.find((pres) => pres.id === prescription.id).prescriptionItemInfo = true;
      this.prescriptionOpened = prescription;
    },
    async openUrl(prescription) {
      if (typeof prescription.type !== 'undefined' && prescription.type === 'electronic' && prescription.url !== null) {
        window.open(prescription.url);
      } else if (prescription?.type === PRESCRIPTION_TYPES.PARAPHARMACY) {
        await this.download({
          url: parapharmacyPrescriptionPdfUrl(prescription.id),
          name: prescription.id + '.pdf',
          type: 'application/pdf',
          download: false,
          open: true,
          openInNewTab: true,
        });
      } else {
        window.open(`pdfprescription/${this.authUser.patient.id}/${prescription.id}`);
      }
    },

    changePage(page) {
      this.currentPage = page;
      this.loadPrescriptions();
    },

    loadPrescriptions() {
      this.$scrollTo('#appRoot');
      this.isLoading = true;
      this.$http
        .get(urlPatient(getPrescriptionsUrl, this.perPage, this.currentPage), { headers: getHeader() })
        .then(res => {
          this.parsePrescriptionPdfToView(res.body.data);
          this.totalResults += res.body._meta.total;
        })
        .catch(err => {
          this.$log.error(err);
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    loadElectronicPrescriptions() {
      this.$scrollTo('#appRoot');
      this.isLoading = true;
      this.$http
        .get(urlPatient(getPrescriptionsUrl + '/electronic', this.perPage, this.currentPage), { headers: getHeader() })
        .then(res => {
          this.parseElectronicToView(res.body.data);
          this.totalResults += res.body._meta.total;
        })
        .catch(err => {
          this.$log.error(err);
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    loadParapharmacyPrescriptions() {
      this.$scrollTo('#appRoot');
      this.isLoading = true;
      this.$http
        .get(parapharmacyPatientPrescriptionsUrl(this.authUser.patient.docline_api_id), { headers: getHeader() })
        .then(res => {
          console.log(res.body.data);
          this.parseParapharmacyToView(res.body.data);
          this.totalResults += res.body.meta.total;
        })
        .catch(err => {
          this.$log.error(err);
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    parsePrescriptionPdfToView(prescriptionsResponse) {
      prescriptionsResponse.forEach(function(data) {
        this.prescriptions.push({
          ...data,
          prescriptionItemInfo: false,
        });
      }, this);
    },

    parseElectronicToView(prescriptionsResponse) {
      prescriptionsResponse.forEach(function(data) {
        this.prescriptions.push({
          id: data.id,
          product: data.medicines[0].name,
          professional: {
            id: data.professional?.id ? data.professional?.id : 0,
            name: data.professional?.name ? data.professional?.name : '',
            image: data.professional?.image ? data.professional?.image : '',
          },
          createdAt: data.medicines[0].dateStart,
          downloading: false,
          url: data.url,
          type: 'electronic',
          prescriptionItemInfo: false,
        });
      }, this);
    },

    parseParapharmacyToView(prescriptionsResponse) {
      for (const prescription of prescriptionsResponse) {
        this.prescriptions.push({
          id: prescription.id,
          product: prescription.data?.parapharmacy_product?.name ?? this.$t('parapharmacy.prescription'),
          professional: {
            id: prescription.professionalId,
            name: `${prescription.professional?.firstName} ${prescription.professional?.lastName}`,
            image: prescription.professional?.avatar ? prescription.professional?.avatar : '',
          },
          createdAt: prescription.createdAt,
          downloading: false,
          url: parapharmacyPrescriptionPdfUrl(prescription.id),
          type: 'parapharmacy',
          prescriptionItemInfo: false,
        });
      }
    },
  },
};
</script>

<style lang="scss">
$black: #212121;
$grey: #9e9e9e;

.prescriptions {
  &__prescription {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: rem(12);
    padding-bottom: rem(12);
    gap: rem(20);
  }

  &__professional {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: rem(12);
    padding-bottom: rem(12);
    gap: rem(8);
  }

  &__professional-name {
    color: $black;
    font-weight: 500;
    font-size: 1rem;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: rem(10);
  }

  &__datetime {
    color: $black;
    font-weight: 500;
    font-size: 1rem;
  }

  &__title {
    color: $black;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1;
  }

  &__subtitle {
    color: $grey;
    font-size: 1rem;
  }

  &__icon {
    width: 2rem;

    & img {
      width: 2rem;
    }
  }
}
</style>
